<template>
    <sweet-modal
      ref="infoRedirectMessage"
      icon="info"
      overlay-theme="dark"
    >
      <div class="termsOfUseContent">
        <h1 class="Headline is-size-4" v-t="`redirectMessage.title`" />
        <p  v-html="translateWithMymedelString(`redirectMessage.${redirectMessageKey.toLowerCase()}`)" />
      </div>
        <div class="row">
            <button type="button" class="Button" @click="closeInfoRedirectMessage">
              {{ $t(`redirectMessage.closeButtonText`) }}
            </button>
        </div>
    </sweet-modal>
</template>

<script>
import localeMessages from '@/mixins/localeMessages'

export default {
  name: 'InfoRedirectMessage',

  mixins: [ localeMessages ],

  data () {
    return {
    }
  },

  computed: {
    redirectMessageKey () {
      if (this.$route.query.origin) {
        return this.$route.query.origin
      } else {
        return ''
      }
    }
  },

  methods: {
    closeInfoRedirectMessage () {
      this.$refs.infoRedirectMessage.close()
    },
    open () {
      this.$refs.infoRedirectMessage.open()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../styles/_component-imports';

.linkButton, .linkButton:hover{
  background:none;
  color:#979594;
}

.row {
  margin-top: 10px;
}

.alert {
  position: relative;
  padding: .75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: .25rem;
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.alert-info {
    color: #0c5460;
    background-color: #d1ecf1;
    border-color: #bee5eb;
}
</style>
