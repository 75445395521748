<template>
  <image-content has-grey-bg show-logo show-logout>
    <template #image-headline>
      <h1
        class="Headline is-size-4"
        v-t="'pages.profileEdit.title'"
      />
    </template>

    <template #image>
      <figure>
        <img
          :src="imageUrl"
          :alt="$t(`${editType}`)"
        />
      </figure>
    </template>

    <template #content>
      <div id="RegisterForm" class="Content has-grey-bg">
        <info-redirect-message ref="redirectMessageModal" />
        <component :is="editType" is-edit ref="completeRegistrationForm" :redirectUrl="redirectUrl"/>
      </div>
    </template>
  </image-content>
</template>

<script>
import { mapGetters } from 'vuex'
import ImageContent from '@/layouts/ImageContent'
import EmailVerificationDialog from '@/components/EmailVerificationDialog.vue'
import registrationSteps from '@/mixins/registrationForm.js'
import InfoRedirectMessage from '@/components/InfoRedirectMessage.vue'
export default {
  name: 'PortalProfileComplete',

  metaInfo () {
    return {
      title: this.$i18n.t('pages.profileEdit.meta.title')
    }
  },

  mixins: [ registrationSteps ],

  components: {
    EmailVerificationDialog,
    ImageContent,
    CompleteRegistrationProfessional: () => import('@/components/CompleteRegistrationProfessional.vue'),
    CompleteRegistrationBasic: () => import('@/components/CompleteRegistrationBasic.vue'),
    InfoRedirectMessage
  },

  data () {
    return {
      editType: 'complete-registration-basic'
    }
  },

  watch: {
    isEmailVerified: function () {
      if (this.isDataComplete && this.isEmailVerified) {
        this.$refs.completeRegistrationForm.setToDone()
      }
    }
  },

  computed: {
    ...mapGetters('user', [
      'user',
      'isProfessional',
      'isCareGiver',
      'userLoaded',
      'isEmailVerified'
    ]),

    redirectUrl () {
      if (this.$route.query.state) {
        return `https://${process.env.VUE_APP_AUTH0_DOMAIN}/continue?state=${this.$route.query.state}`
      }
      return ''
    },

    isDataComplete () {
      return this.userLoaded
    },

    isRedirectedUser () {
      return this.$route.query.origin
    },

    redirectState () {
      return this.$route.query.state
    },

    redirectMessageKey () {
      return this.$route.query.origin
    },

    imageUrl () {
      if (this.editType === 'complete-registration-professional') {
        return require(`@/assets/images/avatar-professional.png`)
      } else {
        return require(`@/assets/images/avatar-recipient.png`)
      }
    }
  },

  mounted: function () {
    // default to professional form for MED-EL employees
    let auth0Id = this.$auth0.user.value.sub
    if (auth0Id.startsWith('waad') || auth0Id.startsWith('adfs')) {
      this.editType = 'complete-registration-professional'
    }
    if (this.isRedirectedUser) {
      this.$refs.redirectMessageModal.open()
    }
  },

  methods: {
  }
}
</script>
<style lang="scss">
@import '../styles/_component-imports.scss';

</style>

<style scoped>
.notify{
  position: relative;
  padding: .75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: .25rem;
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}

.modalContent{
  margin: 15% 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>
