<template>
    <sweet-modal
      ref="verifyEmailModal"
      hide-close-button
      blocking
      icon="info"
      overlay-theme="dark"
    >
      <div class="termsOfUseContent">
        <h1 class="Headline is-size-4" v-t="'verifyEmail.title'" />
        <p  v-html="$t('verifyEmail.infoMessage')" >
        </p>

        <div v-if="emailVerificationMessage" class="alert" :class="emailVerificationMessageClass">{{emailVerificationMessage}}</div>

        <div class="content sub-headline">
        <div class="row">
          <button type="button" class="Button" @click="sendVerificationEmail">
            {{ $t('verifyEmail.sendVerificationEmailButtonText') }}
          </button>
        </div>
        <div class="row">
          <button type="button" class="Button" @click="reloadEmailVerificationStatus">
            {{ $t('verifyEmail.reloadButtonText') }}
          </button>
        </div>
        <div class="row dialog-logout-btn"><logout-button /></div>
        </div>
        <a v-if="!this.isMedelEmployee" @click="changeEmail" class="change-email" onclick="">{{ $t('changeEmail.title') }}</a>
      </div>
    </sweet-modal>
</template>
<script>
import { mapGetters } from 'vuex'
import LogoutButton from '@/components/partials/LogoutButton.vue'

export default {
  name: 'EmailVerificationDialog',
  components: {
    LogoutButton
  },

  data () {
    return {
      emailVerificationMessage: '',
      emailVerificationMessageClass: ''
    }
  },

  computed: {
    ...mapGetters('user', [
      'isEmailVerified'
    ]),

    isEmailVerificationRequired () {
      const isMedelEmployee = this.$store.getters['user/isMedelEmployee']
      if (isMedelEmployee) {
        return false
      }
      return !this.$auth0.user.value.email_verified
    }
  },

  watch: {
    isEmailVerified: function () {
      if (this.isEmailVerified) {
        this.$refs.verifyEmailModal.close()
      }
    }
  },

  methods: {

    open () {
      this.$refs.verifyEmailModal.open()
    },

    async reloadEmailVerificationStatus () {
      this.emailVerificationMessage = ''
      this.emailVerificationMessageClass = ''
      try {
        await this.$auth0.getAccessTokenSilently({
          cacheMode: 'off',
          detailedResponse: true
        })

        if (this.isEmailVerificationRequired) {
          this.emailVerificationMessageClass = 'alert-info'
          this.emailVerificationMessage = this.$i18n.t('verifyEmail.dataReloadedButNotVerified')
        } else {
          // email has been verified, we can either display a message to the user or just close the dialog.
          this.$refs.verifyEmailModal.close()
        }
      } catch (e) {
        // eslint-disable-next-line
        console.log('Could not reload email verification status.', e)
        this.emailVerificationMessageClass = 'alert-danger'
        this.emailVerificationMessage = this.$i18n.t('verifyEmail.errorReloadingUserData')
      }
    },

    changeEmail () {
      this.$router.push('/change-email')
    },

    async sendVerificationEmail () {
      this.emailVerificationMessage = ''
      this.emailVerificationMessageClass = ''
      try {
        await this.$store.dispatch('user/sendVerificationEmail')
        this.emailVerificationMessageClass = 'alert-success'
        this.emailVerificationMessage = this.$i18n.t('verifyEmail.successResendingVerificationEmail')
      } catch (err) {
        // eslint-disable-next-line
        console.log('Error resending verification email', err)
        this.emailVerificationMessageClass = 'alert-danger'
        this.emailVerificationMessage = this.$i18n.t('verifyEmail.errorResendingVerificationEmail')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../styles/_component-imports';

.linkButton, .linkButton:hover{
  background:none;
  color:#979594;
}

.row {
  margin-top: 10px;
}

.change-email{
  font-size: small;
  text-decoration: underline;
  cursor: pointer;
}

.dialog-logout-btn {
  margin-top: 3em;
}

.alert {
  position: relative;
  padding: .75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: .25rem;
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.alert-info {
    color: #0c5460;
    background-color: #d1ecf1;
    border-color: #bee5eb;
}
</style>
